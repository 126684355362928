
import Vue from "vue";
import SelectBadgesModal from "@/components/Modals/SelectBadgesModal.vue";
import BadgesInfoModal from "@/components/Modals/BadgesInfoModal.vue";
import RatingInfoModal from "@/components/Modals/RatingInfoModal.vue";

export default Vue.extend({
  name: "MentorStatistics",
  components: {
    SelectBadgesModal,
    BadgesInfoModal,
    RatingInfoModal
  },
  props: {
    isProfileOwner: Boolean,
    mentorStatistics: Object,
    profile: Object
  },
  data() {
    return {
      maxMentorEvaluation: 10,
      completeBadgeValue: 1000,
      // Mentoring sessions items
      mentoringSessions: {
        delivered: {
          label: (this as any).$t("profile.statisticmodalSessionsDelivered"),
          icon: "session-delivered"
        },
        receivedRatings: {
          label: (this as any).$t("profile.statisticmodalRatingsReceived"),
          icon: "rating"
        },
        rating: {
          label: (this as any).$t("profile.statisticmodalAvgSessionRating"),
          icon: "star-rounded",
          maxValue: 10
        },
        peopleMentored: {
          label: (this as any).$t("profile.statisticmodalPeopleMentored"),
          icon: "users"
        },
        mostGivenBadge: {
          label: (this as any).$t("profile.statisticmodalMostGivenBadge"),
          icon: "badge",
          color: "primary"
        }
      },
      // Mentorcasts data items
      mentorcasts: {
        delivered: {
          label: (this as any).$t("profile.statisticmodalMentorcastsDelivered"),
          icon: "scene"
        },
        receivedRatings: {
          label: (this as any).$t("profile.statisticmodalRatingsReceived"),
          icon: "rating"
        },
        rating: {
          label: (this as any).$t("profile.statisticmodalAvgMentorcastsRating"),
          icon: "star-rounded",
          maxValue: 10
        },
        scheduled: {
          label: (this as any).$t("profile.statisticmodalMentorcastScheduled"),
          icon: "scene"
        },
        usersPerMentorcast: {
          label: (this as any).$t(
            "profile.statisticmodalAvgNoOfUsersPerMentorcast"
          ),
          icon: "profile"
        },
        pricePerSeat: {
          label: (this as any).$t(
            "profile.statisticmodalAvgSeatPricePerMentorcast"
          ),
          icon: "currency"
        },
        mostGivenBadge: {
          label: (this as any).$t("profile.statisticmodalMostGivenBadge"),
          icon: "badge",
          color: "primary"
        }
      }
    };
  }
});
